<template>
  <div>
    <nav class="navbar">
      <div class="navbar-wrapper">
        <ButtonIcon @click.native="exitForm()"/>
        <ProgressIndicator :currentPage="1" :totalPages="3"/>
      </div>
    </nav>

    <form
        @submit.prevent="submit"
        class="form"
        enctype="multipart/form-data"
    >
      <div class="form-page">
        <h2 class="form-page-title">
          {{ $t('business_request.page_1.title') }}
        </h2>

        <div class="w-full mb-6 sm:mb-8">
          <InputText
              :label="`${$t('business_request.page_1.first_name.label')}`"
              name="first-name"
              :placeholder="
              `${$t('business_request.page_1.first_name.placeholder')}`
            "
              class="w-full mb-8"
              :disabled="loading"
              :errorMsg="firstNameErrMsg"
              :showErrMsg="$v.form.firstName.$error"
              v-model="form.firstName"
          />

          <InputText
              :label="`${$t('business_request.page_1.last_name.label')}`"
              name="last-name"
              :placeholder="
              `${$t('business_request.page_1.last_name.placeholder')}`
            "
              class="w-full mb-8"
              :disabled="loading"
              :errorMsg="lastNameErrMsg"
              :showErrMsg="$v.form.lastName.$error"
              v-model="form.lastName"
          />

          <InputText
              name="email"
              :label="`${$t('business_request.page_1.email.label')}`"
              :placeholder="`${$t('business_request.page_1.email.placeholder')}`"
              class="w-full mb-8"
              :disabled="loading"
              :errorMsg="emailErrMsg"
              :showErrMsg="$v.form.email.$error"
              v-model="form.email"
          />

          <div
              class="mb-8">
            <InputPhone
                v-model="form.phone"
                :errorMsg="phoneErrMsg"
                :showErrMsg="$v.form.phone.$error"
                @select="updateNumber"
                @blur="$v.form.phone.$touch()"
            />
          </div>

          <div class="mt-8">
            <InputUpload
                :label="`${$t('business_request.page_1.upload.label')}`"
                name="personal - doc"
                class="w-full mt-3"
                :title="`${$t('business_request.page_1.upload.title')}`"
                :text="`${$t('business_request.page_1.upload.text')}`"
                @upload="upload"
                :isError="$v.form.personalDoc.$error"
                v-model="form.personalDoc"
            />

            <span
                class="mt-2 font-sans text-sm font-medium error-msg text-red-base"
                v-if="
                $v.form.personalDoc.$error && !$v.form.personalDoc.requiredIf
              "
            >
              {{ $t('business_request.page_1.upload.error') }}
            </span>
          </div>
        </div>
        <ButtonPrimary :text="$t('trip_request.button')" class="desktop-cta"/>
      </div>

      <div class="bottom-nav">
        <div class="bottom-nav-wrapper">
          <ButtonPrimary
              class="block w-full h-12 px-8"
              :text="`${$t('business_request.button')}`"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {InputPhone, InputUpload} from "@/components"

const {required, email, requiredIf} = require('vuelidate/lib/validators');

import {mapGetters} from 'vuex';
import ButtonIcon from "@/components/buttons/ButtonIcon";

export default {
  name: 'personal-details',
  components: {
    ButtonIcon,
    InputUpload,
    InputPhone,
  },
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        personalDoc: null,
      },
      loading: false,
    };
  },
  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
      personalDoc: {
        required: requiredIf(function () {
          return !this.form.personalDoc;
        }),
      },
    },
  },
  computed: {
    ...mapGetters({
      personalDetails: 'partner/personalDetails'
    }),
    firstNameErrMsg() {
      let msg;
      if (!this.$v.form.firstName.required) {
        msg = this.$t('business_request.page_1.first_name.error_1'); //First Name is required
      }
      return msg;
    },
    lastNameErrMsg() {
      let msg;
      if (!this.$v.form.lastName.required) {
        msg = this.$t('business_request.page_1.last_name.error_1'); //Last Name is required
      }
      return msg;
    },
    emailErrMsg() {
      let msg;
      if (!this.$v.form.email.email) {
        msg = this.$t('business_request.page_1.email.error_1'); //Please enter a valid email"
      }
      if (!this.$v.form.email.required) {
        msg = this.$t('business_request.page_1.email.error_2'); //Email is required
      }
      return msg;
    },
    phoneErrMsg() {
      let msg;

      if (!this.$v.form.phone.required) {
        msg = this.$t('trip_request.form_6.input_5.error');
      }
      return msg;
    },
  },
  methods: {

    updateNumber(value) {
      this.form.phone = value;
    },
    submit() {
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return false;
      }
      this.$store.commit('partner/SET_PERSONAL_DETAILS', this.form);
      this.$router.push('/business/company');
    },
    upload(val) {
      this.form.personalDoc = val;
    },
    exitForm() {
      this.$router.push("/business");
    }
  },
  mounted() {
    if (Object.keys(this.personalDetails).length) {
      this.form = this.personalDetails;
    }
  },
};
</script>

<style scoped>
.field-container {
  @apply flex flex-wrap w-full;
}

.input {
  @apply leading-tight p-3 border rounded focus:border-teal-base focus:ring-teal-lighter focus:ring-2 text-black-base placeholder-black-lightest;
}

.error-msg {
  @apply block mt-2 font-sans text-sm font-medium text-red-base;
}

.navbar {
  @apply top-0 flex flex-wrap items-center w-full h-20 px-4 bg-white md:px-12;
}

.navbar-wrapper {
  @apply relative flex items-center justify-between w-full h-12 max-w-screen-md transform -translate-x-1/2 left-1/2;
}

.form {
  @apply w-full px-4 pb-48 mt-10 md:px-12;
}

.form-page {
  @apply relative max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.form-page-title {
  @apply mb-12 font-sans text-xl font-bold  sm:text-2xl text-black-base;
}

.bottom-nav {
  @apply fixed bottom-0 left-0 w-full p-4 bg-white;
  @apply border-t border-grey-base sm:mt-16;
}

.bottom-nav-wrapper {
  @apply relative flex items-center justify-between;
  @apply max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.bottom-nav {
  @apply fixed bottom-0 left-0 w-full p-4 bg-white border-t border-grey-base sm:mt-16;
  @apply block sm:hidden;
}

.bottom-nav-wrapper {
  @apply relative flex items-center justify-between max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.desktop-cta {
  @apply hidden sm:block h-12 px-8 w-full mt-8;
}
</style>
