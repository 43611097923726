import RadioButtonGroup from "@/components/inputs/RadioButtonGroup";
import TextArea from "@/components/inputs/TextArea";
import InputUpload from "@/components/inputs/InputUpload";
import MultiSelect from "@/components/inputs/MultiSelect/MultiSelect";
import InputPhone from '@/components/inputs/InputPhone';

export{
    RadioButtonGroup,
    TextArea,
    InputPhone,
    InputUpload,
    MultiSelect
}